import { ApiError } from 'lib/error';
import { useImpression } from 'data/tracking/useImpression';
import { error as errorEvents } from 'lib/trackingEvents';
import { usePlan } from 'data/plan/usePlan';

type ErrorPageType =
  | 'address_mismatch'
  | 'country_mismatch'
  | 'generic'
  | 'invite_has_expired'
  | 'join_own_plan'
  | 'partner_transition'
  | 'plan_is_full'
  | 'user_has_failed_reverification'
  | 'user_has_plan_hopped'
  | 'user_has_failed_generic_check'
  | 'restricted_country'
  | 'member_removal_page'
  | 'audiobooks_offer_transition'
  | 'gpb_offer_transition';

export const useErrorPageView = (errorType: ErrorPageType, error: ApiError) => {
  const { planType } = usePlan();
  useImpression(
    errorEvents.pageView(errorType, planType, {
      type: error.type,
      code: error.code,
    }),
  );
};
